<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
         {{msg('You are not authorized')}}! 🔐
        </h2>
        <p class="mb-2">
          {{msg('You don’t have permission to access this page')}}.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="$router.push('/')"
        >{{msg('Back to Home')}}</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      upImg : require('@/assets/images/pages/not-authorized-dark.svg'),
    }
  },
  computed: {
    ...mapGetters('appConfig', ['layoutSkin']),
    imgUrl() {
      return (this.layoutSkin=== 'dark') ? this.upImg : this.downImg
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
